<template>
  <view v-if= "Object.keys(userInfo).length > 0">
    <nut-cell title="用户ID" :desc="userInfo.user_id"></nut-cell>
    <nut-cell title="姓名" :desc="userInfo.user_realname"></nut-cell>
    <nut-cell title="手机号" :desc="userInfo.user_mobile"></nut-cell>
  </view>
  <view v-else>
    已经成功退出登录
  </view>
  <view>
    <nut-button type="danger" @click="logout">退出登录</nut-button>、
  </view>

</template>

<script>
import utils from "../../utils";
import { useRouter } from "vue-router";
import { ref } from "vue";
import * as userApi from "../../api/user";
import { Toast } from "@nutui/nutui";
export default {
  setup() {
    const userInfo = ref({});
    const router = useRouter();
    const logout = () => {
      utils.removeToken();
      Toast.text("退出成功");
      getUserInfo();
    };
    
    const goLogin = () => {
      router.push("/login");
    };
    
    const getUserInfo = async () => {
      const res = await userApi.getUserInfo({"is_jump_login":false});
      if(res.code ==0 ){
        userInfo.value = res.data;
      }else{
        userInfo.value ={};
      }
      
    };
    getUserInfo();
    return {
      logout,
      goLogin,
      userInfo,
    }
  },
}
</script>

<style scoped>

</style>